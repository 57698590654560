var j = jQuery.noConflict();
j(document)
.ready(
		function() {
			
			function doAjaxPost(par1,par2){
				j.ajax({
				    type: "POST",
				    url: "srubDisplay",
				    data: {rubId: par1, srubId: par2},
				    success: function(response){
				    	//  we have the response
				    	console.log("Ok");
				    }
					
				});

			}
});