var Tempo=1000;var MouseStatus="out";var CurrentSsMenu=0;var TimeoutSet;var selectedMenu=0;function hideSsElementDecal(ssElementH)
{if(CurrentSsMenu!=ssElementH||MouseStatus=="on")return;hideSsElementReal(ssElementH);}
function activSmenu(selectMenu)
{window.clearTimeout(TimeoutSet);MouseStatus="on";if(CurrentSsMenu==selectMenu)return;if(selectedMenu!=selectMenu)
{if(selectMenu!=0)
{document.getElementById("mT"+selectMenu).className+="Sel";document.getElementById("mS"+selectMenu).style.display="block";}
if(selectedMenu!=0)
{var reg=new RegExp("(Sel)","g");document.getElementById("mT"+selectedMenu).className=document.getElementById("mT"+selectedMenu).className.replace(reg,"");document.getElementById("mS"+selectedMenu).style.display="none";}
selectedMenu=selectMenu;}
CurrentSsMenu=selectMenu;}
function desactSmenu(selectMenu,sClass)
{MouseStatus="out";if(CurrentSsMenu==0||CurrentSsMenu!=selectMenu)return;TimeoutSet=window.setTimeout("desactRealSmenu("+selectMenu+",'"+sClass+"')",Tempo);}
function desactRealSmenu(selectMenu,sClass)
{if(MouseStatus=="on")return;if(CurrentSsMenu==0)return;activSmenu(0);CurrentSsMenu=0;}
function geturlMenu(sUrl)
{window.location.href=sUrl;}
function addToFavorites(){if(navigator.appName!='Microsoft Internet Explorer')
{window.sidebar.addPanel("diakabana.com","https://www.diakabana.com","");}
else{window.external.AddFavorite("https://www.diakabana.com","diakabana.com");}}
